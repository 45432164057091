/* themes.css */
#myBgCanvas {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  height: 100vh;
}

.page-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  padding: 1rem;
  height: 100%;
}

.page-inner-container {
  background-color: rgba(255, 255, 255, 0.9);
  padding-top: 2rem;
  padding-bottom: 3rem;
  padding-left: 2rem;
  padding-right: 2rem;
  border-radius: 10px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  width: 90%;
  max-width: 900px;
}

.typist-text {
  font-size: 2rem;
  font-family: 'Roboto', sans-serif;
  color: #4e169e;
  margin-bottom: 1.5rem;
  display: inline;
}

.nav-gear-button {
  margin-left: auto;
}

.gear-icon {
  font-size: 20px;
  transition: transform 0.3s ease;
  color: #d6d8db;
}

.gear-icon:hover {
  transform: rotate(360deg);
}

#dropdown-basic-button::after {
  display: none;
}

.theme-dropdown-menu {
  position: absolute;
  min-width: 0;
  padding: 0.25rem 0.25rem;
  background-color: rgba(255, 255, 255, 0.9) !important;
}

.theme-dropdown-menu .dropdown-item {
  padding: 0.25rem 0.5rem;
}

.theme-dropdown-menu .dropdown-item:active {
  background-color: #d6d8db;
  transform: rotate(360deg);
}

.theme-dropdown-menu.show {
  min-width: auto;
  max-width: 100%;
  padding: 0.25rem 0;
}

.dropdown-item svg {
  color: #0056b3;
}


/* theme1: Light mode */
.theme1 {
  background-color: #ffffff;
  color: #333333;
  font-family: 'Roboto', sans-serif;
}

.theme1 .page-inner-container {
  background-color: rgb(208,206,181,0.2);
  padding: 20px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1), 0px 1px 3px rgba(0, 0, 0, 0.06);
}

.theme1 a {
  color: #0056b3;
  text-decoration: none;
}

.theme1 a:hover {
  color: #43589b;
}

.theme1 .nav-link:hover {
  color: #43589b;
}

.theme1 .card {
  background-color: #ffffff;
  border-color: #d6d8db;
}

.theme1 .btn {
  background-color: #0056b3;
  border-color: #0056b3;
  color: #ffffff;
}

.theme1 .btn:hover {
  background-color: #43589b;
  border-color: #43589b;
  color: #ffffff;
}


/* theme2:  Dark mode */
.theme2 {
  background-color: #333333;
  color: #ffffff;
  font-family: 'Roboto', sans-serif;
  min-height: 100vh;
}

.theme2 .page-inner-container {
  background: #333333;
}

.theme2 .page-inner-container .github-contributions h2{
  color: #a8b2bb;
}

.theme2 .page-inner-container .github-contributions text {
  fill: #a8b2bb;
}

.theme2 a {
  color: #5c6bc0;
  text-decoration: none;
}

.theme2 a:hover {
  color: #a8b2bb;
}

.theme2 .navbar {
  background-color: #212529;
}

.theme2 .nav-link:hover {
  color: #a8b2bb;
}

.theme2 .card {
  background-color: #444444;
  border-color: #565656;
}

.theme2 .btn {
  background-color: #444444;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.theme2 .btn:hover {
  background-color: #666666;
  transform: translateY(-2px);
}

.theme2 .social-icon-link {
  background-color: #d2cdcd;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  margin: 0 5px;
  transition: background-color 0.3s ease;
}

.theme2 .social-icon-link:hover {
  background-color: #f0f0f0;
}

.theme2 .typist-text {
  color: #45599c;
}


/* Starry night theme */
.theme3 #myBgCanvas {
  background: url('../public/images/background.jpg');
  z-index: -1;
}

.theme3 {
  color: #43589b;
  font-family: 'Roboto', sans-serif;
}

.theme3 a {
  color: rgb(115,134,186);
  text-decoration: none;
}

.theme3 a:hover {
  color: rgb(67,88,155);
}

.theme3 .navbar {
  background-color: #212529;
}

.theme3 .nav-link:hover {
  color: #3399ff;
}

.theme3 .btn {
  background-color: rgb(149,169,177);
  border-color: rgb(149,169,177);
  color: #ffffff;
}

.theme3 .btn:hover {
  background-color: #647990;
  border-color: #647990;
  color: #ffffff;
}


/* Blog Page styles */
.blog-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.search-input {
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.blog-actions {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
}

.blog-actions button {
  margin-left: 0.5rem;
}

.blog-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 1rem;
}

.blog-card {
  background-color: #f5f5f5;
  padding: 1rem;
  border-radius: 5px;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1);
}

.blog-title {
  font-size: 1.2rem;
  font-weight: bold;
  color: #007bff;
  text-decoration: none;
  margin-bottom: 0.5rem;
}

.blog-title:hover {
  color: #0056b3;
}

.blog-content {
  font-size: 1rem;
  color: #333;
}


.comment-form button {
  background-color: #0056b3;
  border: none;
  border-radius: 10px;
  color: white;
  text-align: center;
  display: inline-block;
  font-size: 14px;
  transition-duration: 0.4s;
  cursor: pointer;
  padding: 10px 24px;
  margin: 10px 0;
}

.comment-form button:hover {
  background-color: #43589b;
}

.theme2 .comment-form button {
  background-color: #444444;
}

.theme2 .comment-form button:hover {
  background-color: #666666;
}

.theme3 .comment-form button {
  background-color: rgb(149,169,177);
}

.theme3 .comment-form button:hover {
  background-color: #647990;
}


.theme2 .comment small {
  font-size: 0.8rem;
  color: #888888;
}

.theme2 .comment-input {
  background-color: #a8b2bb;
}

.highlight {
  background-color: yellow;
}


