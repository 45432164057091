.blogpost-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
}

h1 {
  font-size: 2rem;
  margin-bottom: 1.5rem;
}

h2 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  margin-top: 2rem;
}

.comments-container {
  margin-bottom: 2rem;
}

.comment {
  margin-bottom: 1rem;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}

.comment p {
  margin-bottom: 0.5rem;
}

.comment small {
  font-size: 0.8rem;
  color: rgba(0, 0, 0, 0.9);
}

.delete-button {
  background-color: rgba(255, 0, 0, 0.3);
  border: none;
  border-radius: 4px;
  padding: 4px 8px;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.8);
  font-size: 0.8rem;
  margin-left: 1rem;
}

.comment-form {
  display: flex;
  gap: 1rem;
}

.comment-form input {
  flex: 1;
  padding: 0.5rem;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

