.profile-image {
  margin-top: 35px;
  border-radius: 10px;
}

.page-inner-container h2 {
  margin-top: 2rem;
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
  color: #5c6bc0;
  border-top: 3px solid #5c6bc0;
  padding-top: 1.5rem;
}

.page-inner-container h3 {
  margin-top: 1.5rem;
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
  color: #5c6bc0;
  border-top: 3px solid #5c6bc0;
  padding-top: 1.5rem;
}

.page-inner-container p {
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 5rem;
  font-size: 1.2rem;
}

.tech-skills-category h4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
  color: #5c6bc0;
  background-color: rgba(92, 107, 192, 0.2);
  border-radius: 5px;
  padding: 0.5rem;
}

.tech-skills {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 1rem;
}

.tech-skill {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0.5rem;
  width: calc(100% / 6 - 1rem);
}

.tech-skill-icon {
  font-size: 3rem;
  margin-bottom: 0.5rem;
  transition: transform 0.2s;
}

.tech-skill-icon:hover {
  transform: translateY(-5px) rotate(10deg);
}

.github-contributions {
  display: block;
  margin: 0 auto;
  width: 100%;
  margin-bottom: 2rem;
  zoom: 1.5;
}

@media (max-width: 768px) {
  .about-me-inner-container {
    padding: 1rem;
    width: 100%;
  }
}



/* Github contributions graph styles */
.page-inner-container .github-contributions h2 {
  margin-top: -1.5rem;
  text-align: left;
  font-size: 0.8rem;
  font-weight: bold;
  color: #333333;
  border-top: none;
  zoom: 1;
}
