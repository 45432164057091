.page-inner-container h1 {
  margin-top: 2rem;
  text-align: center;
}

.page-inner-container p {
  margin-top: 1rem;
  font-size: 1.2rem;
}

.profile-image {
  margin-top: 20px;
  border-radius: 10px;
}

#forum_side_menu {
  position: fixed;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  z-index: 10000;
}

#forum-menu-container {
  display: flex;
  flex-direction: column;
  z-index: 10000;
}

#forum-menu-button {
  background-color: rgba(6, 144, 142, 0.9);
  cursor: pointer;
  z-index: 10000;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.sidebar-buttons-container {
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  width: 0;
  overflow: hidden;
  transition: width 0.3s;
  right: -100px;
  z-index: 10000;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.sidebar-buttons-container.sidebar-visible {
  width: 60px;
  z-index: 10000;
}

.sidebar-item {
  padding: 1rem;
  color: white;
  cursor: pointer;
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  z-index: 1;
}

.sidebar-item:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.sidebar-icon {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.arrow-icon {
  width: 30px;
  height: 100%;
  object-fit: cover;
}


@media (max-width: 992px) {
  .home-page-container .mt-4 {
    margin-bottom: 1rem !important;
  }

  .sidebar-buttons-container {
    width: 0;
  }

  .sidebar-buttons-container.sidebar-visible {
    width: 60px;
  }

  .sidebar-item {
    display: block;
  }

  .sidebar-buttons-container:not(.sidebar-visible) .sidebar-item {
    display: none;
  }
}

@media (min-width: 992px) {
  #forum-menu-button {
    display: none;
  }
}


/* ContactForm.css */
.contact-form-container {
  background-color: rgba(245, 245, 245, 0.9);
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  margin-top: -2rem;
}

.contact-form-container h2 {
  text-align: center;
  margin-bottom: 1rem;
}

.contact-form-container input,
.contact-form-container textarea {
  border: none;
  border-bottom: 1px solid #6c757d;
  background-color: transparent;
  transition: border-color 0.2s;
}

.contact-form-container input:focus,
.contact-form-container textarea:focus {
  outline: none;
  border-color: #06a08e;
}

.contact-form-container button {
  background-color: #06a08e;
  color: white;
  border: none;
  border-radius: 5px;
  transition: background-color 0.2s;
  margin-top: 5px;
  width: 75px;
}

.contact-form-container button:hover {
  background-color: #057c7a;
}

.contact-form-container button:focus {
  outline: none;
}

.contact-form-wrapper {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 2rem;
  margin-bottom: 1rem;
}

@media (max-width: 992px) {
  .page-inner-container .mt-4 {
    margin-top: 2rem !important;
  }
}
